import {Toolbar,SaveButton, List, WithRecord, Datagrid, TextField, DateField, EmailField, BooleanInput, SimpleForm, BooleanField, Show} from 'react-admin';
import axios from "axios";
import { useNotify, useRefresh } from 'react-admin';
import PushPin from '@mui/icons-material/PushPin';
import { Button, Link } from '@mui/material';

const ListUsers = () => {
    const notify = useNotify();
    const refresh = useRefresh();

    const TypeField = ({ record }) => (
        <ul style={{paddingInlineStart: 0}}>
            {record.type.map(item => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    )

    const sendUpdateStatus = async (id, status) => {
        try {
            const token = localStorage.getItem('token') || null
            if (token) {
                const response = await axios.put(
                    process.env.REACT_APP_API_URL + '/users/' + id + '/' +status,
                    {},
                    {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }})
                if (response.status === 200) {
                    notify(`User update`, { type: 'success' });
                    refresh()
                }
            }
        } catch (e) {
            console.error(e)
            notify(`Error`, { type: 'error' });
        }
    }

    const sendInvite = async (id) => {
        try {
            const token = localStorage.getItem('token') || null
            if (token) {
                const response = await axios.post(
                    process.env.REACT_APP_API_URL + '/users/' + id + '/send-invite',
                    {},
                    {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }})
                if (response.status === 200) {
                    notify(`Invitation send`, { type: 'success' });
                    refresh()
                }
            }
        } catch (e) {
            console.error(e)
            notify(`Error`, { type: 'error' });
        }
    }

    const updateEmailConfirm = async (data) => {
        try {
            const token = localStorage.getItem('token') || null
            if (token) {
                const response = await axios.put(
                    process.env.REACT_APP_API_URL + '/users/' + data.id,
                    {
                        emailConfirm: data.emailConfirm
                    },
                    {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }})
                if (response.status === 200) {
                    notify(`User update`, { type: 'success' });
                    refresh()
                }
            }
        } catch (e) {
            console.error(e)
            notify(`Error`, { type: 'error' });
        }
    }
    const SimpleToolBar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    )
    return (
        <List sort={{field: 'createdAt', order: 'DESC'}}>
        
            <Datagrid>
                <DateField source="createdAt"/>
                <TextField source="id" sortable={false}/>
                <TextField source="company"/>
                <EmailField source="email"/>
                <WithRecord label={"Comfirm email"} render={record => {
                    return (
                        <SimpleForm toolbar={<SimpleToolBar/>} onSubmit={updateEmailConfirm}>
                            <BooleanInput defaultValue={record.emailConfirm} source={"emailConfirm"}/>
                        </SimpleForm>
                    )
                }} />
                <TextField source="phone"/>
                <TextField source="siret"/>
                <WithRecord label="Type" render={record => <TypeField record={record}/> } />
                <TextField source="status"/>
                <WithRecord label="Action" render={record => {
                    if (record.status === "pending") {
                        return <div><button style={{
                            backgroundColor: 'green',
                            color: 'white',
                            padding: "5 15",
                            borderRadius: 5,
                            outline: 0,
                            cursor: 'pointer',
                        }} onClick={() => sendUpdateStatus(record.id, 'validate')}>Validate</button></div>
                    } else {
                        return <div><button style={{
                            backgroundColor: 'red',
                            color: 'white',
                            padding: "5 15",
                            borderRadius: 5,
                            outline: 0,
                            cursor: 'pointer',
                        }} onClick={() => sendUpdateStatus(record.id, 'pending')}>Reject</button></div>
                    }
                } } />
                <WithRecord label="Send invite" render={record => {
                   return <Button
                                onClick={() => sendInvite(record.id)}
                            >
                                Send invitation
                            </Button>
                } } />
                <WithRecord label="Switch To" render={record => {
                   return <Button target={"_blank"} href={process.env.REACT_APP_FRONT_URL + '/users/'+record.id +'/switch-to'}>
                            Switch To
                        </Button>
                } } />
            </Datagrid>
        </List>
    )
};

export default ListUsers