import {Toolbar,SaveButton, List, WithRecord, Datagrid, TextField, DateField, EmailField, BooleanInput, SimpleForm, BooleanField, Show} from 'react-admin';
import axios from "axios";
import { useNotify, useRefresh } from 'react-admin';
import { useParams } from 'react-router-dom';

const ListLocation = () => {
    const notify = useNotify();
    const refresh = useRefresh();

    const { userId } = useParams();

    return (
        <List sort={{field: 'createdAt', order: 'DESC'}} filter={{ userId }}>
        
            <Datagrid>
                <TextField source="id" sortable={false}/>
              
              
            </Datagrid>
        </List>
    )
};

export default ListLocation