import axios from "axios";
import { useNotify, useRefresh, useRedirect } from 'react-admin';
import { Create, Form, TextInput, SaveButton, SelectInput } from 'react-admin';
import { Grid } from '@mui/material';

const CreateUser = () => {
    const notify = useNotify();
    const redirect = useRedirect();

  

    const createUser = async (data) => {
        try {
            const token = localStorage.getItem('token') || null
            if (token) {
                const response = await axios.post(
                    process.env.REACT_APP_API_URL + '/users',
                    {
                        ...data
                    },
                    {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }})
                if (response.status === 200) {
                    notify(`User create`, { type: 'success' });
                    redirect('list', 'users');
                }
            }
        } catch (e) {
            console.error(e)
            notify(`Error` + e.response.data?.message ? e.response.data?.message : '', { type: 'error' });
        }
    }

    return (
        <Create>
            <Form onSubmit={createUser}>
                <Grid container>
                    <Grid item xs={12} padding={3}>
                        <TextInput source="company" fullWidth />
                    </Grid>
                    <Grid item xs={6} padding={3}>
                        <TextInput source="email" fullWidth />
                    </Grid>
                    <Grid item xs={6} padding={3}>
                        <TextInput source="phone" fullWidth />
                    </Grid>
                    <Grid item xs={12} padding={3}>
                        <TextInput source="siret" fullWidth />
                    </Grid>
                    <Grid item xs={12} padding={3}>
                        <SelectInput source="type" choices={[
                            { id: 'producer', name: 'Producer' },
                            { id: 'collector', name: 'Collector' },
                        ]} />
                    </Grid>
                    <Grid item xs={12} padding={12} textAlign={'center'}>
                        <SaveButton label="Add user" />
                    </Grid>
                </Grid>
            </Form>
        </Create>      
    )
};

export default CreateUser