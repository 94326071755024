import axios from "axios";
import { AuthProvider } from 'react-admin';

const authProvider: AuthProvider = {
    checkAuth: () => localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(''),
    logout: params => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    login: async ({ username, password }) =>  {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/login', {
                email: username, password
            }, {headers: {'Content-Type': 'application/json' }})
            localStorage.setItem('token', response.data.token);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error.status);
        }
    },
};

export default authProvider;