import * as React from "react";
import {Admin, ListGuesser, Resource, Authenticated} from 'react-admin';
import authProvider from "../config/authProvider.ts";
import dataProvider from "../config/dataProvider";
import ListUsers from "../users/ListUsers"
import UserIcon from '@mui/icons-material/People';
import { defaultTheme } from 'react-admin';
import { Route } from 'react-router-dom';
import CreateUser from '../users/create-user'
import ListLocations from "../locations/ListLocations";

const theme = {
    ...defaultTheme,
    sidebar: {
        width: 150, // The default value is 240
        closedWidth: 55, // The default value is 55
    },
};
const App = () => (
        <Admin theme={theme} dataProvider={dataProvider} authProvider={authProvider}>
            <Resource name="users" list={ListUsers} icon={UserIcon} create={CreateUser}>
                <Route path=":userId/locations" element={<ListLocations/>} />
            </Resource>
        </Admin>
    );

export default App;
